import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _808fabc0 = () => interopDefault(import('../pages/company-partners/index.vue' /* webpackChunkName: "pages/company-partners/index" */))
const _086d3916 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _1063e327 = () => interopDefault(import('../pages/partner-companies/index.vue' /* webpackChunkName: "pages/partner-companies/index" */))
const _142399df = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _4add47e8 = () => interopDefault(import('../pages/admin/affiliations/index.vue' /* webpackChunkName: "pages/admin/affiliations/index" */))
const _5e5f2b40 = () => interopDefault(import('../pages/admin/insights/index.vue' /* webpackChunkName: "pages/admin/insights/index" */))
const _684d111f = () => interopDefault(import('../pages/admin/projects/index.vue' /* webpackChunkName: "pages/admin/projects/index" */))
const _6977f559 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _a447fc9a = () => interopDefault(import('../pages/projects/helpers.ts' /* webpackChunkName: "pages/projects/helpers" */))
const _009418b0 = () => interopDefault(import('../pages/users/community.vue' /* webpackChunkName: "pages/users/community" */))
const _1352f44a = () => interopDefault(import('../pages/users/dashboard.vue' /* webpackChunkName: "pages/users/dashboard" */))
const _cb7870a0 = () => interopDefault(import('../pages/users/login.vue' /* webpackChunkName: "pages/users/login" */))
const _2685f6af = () => interopDefault(import('../pages/users/notifications.vue' /* webpackChunkName: "pages/users/notifications" */))
const _139d7230 = () => interopDefault(import('../pages/users/profile.vue' /* webpackChunkName: "pages/users/profile" */))
const _98ad3538 = () => interopDefault(import('../pages/users/request-password-reset.vue' /* webpackChunkName: "pages/users/request-password-reset" */))
const _3997c0ac = () => interopDefault(import('../pages/users/settings.vue' /* webpackChunkName: "pages/users/settings" */))
const _61a3ed9e = () => interopDefault(import('../pages/users/onboarding/interests.vue' /* webpackChunkName: "pages/users/onboarding/interests" */))
const _5e3b07dd = () => interopDefault(import('../pages/users/onboarding/job-title.vue' /* webpackChunkName: "pages/users/onboarding/job-title" */))
const _4625be22 = () => interopDefault(import('../pages/onboarding/partnerships/_slug/index.vue' /* webpackChunkName: "pages/onboarding/partnerships/_slug/index" */))
const _5d028c42 = () => interopDefault(import('../pages/users/conversations/_id.vue' /* webpackChunkName: "pages/users/conversations/_id" */))
const _67bf1434 = () => interopDefault(import('../pages/users/create-password/_token.vue' /* webpackChunkName: "pages/users/create-password/_token" */))
const _8860e592 = () => interopDefault(import('../pages/users/reset-password/_token.vue' /* webpackChunkName: "pages/users/reset-password/_token" */))
const _0c6bbdc9 = () => interopDefault(import('../pages/admin/affiliations/_slug/edit.vue' /* webpackChunkName: "pages/admin/affiliations/_slug/edit" */))
const _84bcc708 = () => interopDefault(import('../pages/admin/affiliations/_slug/projects/index.vue' /* webpackChunkName: "pages/admin/affiliations/_slug/projects/index" */))
const _497a309c = () => interopDefault(import('../pages/admin/affiliations/_slug/team.vue' /* webpackChunkName: "pages/admin/affiliations/_slug/team" */))
const _156e6210 = () => interopDefault(import('../pages/admin/users/_slug/edit.vue' /* webpackChunkName: "pages/admin/users/_slug/edit" */))
const _65cab506 = () => interopDefault(import('../pages/admin/users/_slug/permissions.vue' /* webpackChunkName: "pages/admin/users/_slug/permissions" */))
const _2a637578 = () => interopDefault(import('../pages/access/_slug/index.vue' /* webpackChunkName: "pages/access/_slug/index" */))
const _7eca0d5b = () => interopDefault(import('../pages/company-partners/_slug/index.vue' /* webpackChunkName: "pages/company-partners/_slug/index" */))
const _79db320e = () => interopDefault(import('../pages/events/_slug/index.vue' /* webpackChunkName: "pages/events/_slug/index" */))
const _99fc8ce2 = () => interopDefault(import('../pages/posts/_id/index.vue' /* webpackChunkName: "pages/posts/_id/index" */))
const _126cf897 = () => interopDefault(import('../pages/projects/_slug.vue' /* webpackChunkName: "pages/projects/_slug" */))
const _1fc6edd8 = () => interopDefault(import('../pages/users/_slug/index.vue' /* webpackChunkName: "pages/users/_slug/index" */))
const _bbed0a4c = () => interopDefault(import('../pages/affiliations/_slug/insights.vue' /* webpackChunkName: "pages/affiliations/_slug/insights" */))
const _f9179d10 = () => interopDefault(import('../pages/affiliations/_slug/overview.vue' /* webpackChunkName: "pages/affiliations/_slug/overview" */))
const _194edbbc = () => interopDefault(import('../pages/affiliations/_slug/projects/index.vue' /* webpackChunkName: "pages/affiliations/_slug/projects/index" */))
const _6cdd0048 = () => interopDefault(import('../pages/affiliations/_slug/team.vue' /* webpackChunkName: "pages/affiliations/_slug/team" */))
const _8ab8e740 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/company-partners",
    component: _808fabc0,
    name: "company-partners"
  }, {
    path: "/media",
    component: _086d3916,
    name: "media"
  }, {
    path: "/partner-companies",
    component: _1063e327,
    name: "partner-companies"
  }, {
    path: "/projects",
    component: _142399df,
    name: "projects"
  }, {
    path: "/admin/affiliations",
    component: _4add47e8,
    name: "admin-affiliations"
  }, {
    path: "/admin/insights",
    component: _5e5f2b40,
    name: "admin-insights"
  }, {
    path: "/admin/projects",
    component: _684d111f,
    name: "admin-projects"
  }, {
    path: "/admin/users",
    component: _6977f559,
    name: "admin-users"
  }, {
    path: "/projects/helpers",
    component: _a447fc9a,
    name: "projects-helpers"
  }, {
    path: "/users/community",
    component: _009418b0,
    name: "users-community"
  }, {
    path: "/users/dashboard",
    component: _1352f44a,
    name: "users-dashboard"
  }, {
    path: "/users/login",
    component: _cb7870a0,
    name: "users-login"
  }, {
    path: "/users/notifications",
    component: _2685f6af,
    name: "users-notifications"
  }, {
    path: "/users/profile",
    component: _139d7230,
    name: "users-profile"
  }, {
    path: "/users/request-password-reset",
    component: _98ad3538,
    name: "users-request-password-reset"
  }, {
    path: "/users/settings",
    component: _3997c0ac,
    name: "users-settings"
  }, {
    path: "/users/onboarding/interests",
    component: _61a3ed9e,
    name: "users-onboarding-interests"
  }, {
    path: "/users/onboarding/job-title",
    component: _5e3b07dd,
    name: "users-onboarding-job-title"
  }, {
    path: "/onboarding/partnerships/:slug",
    component: _4625be22,
    name: "onboarding-partnerships-slug"
  }, {
    path: "/users/conversations/:id?",
    component: _5d028c42,
    name: "users-conversations-id"
  }, {
    path: "/users/create-password/:token?",
    component: _67bf1434,
    name: "users-create-password-token"
  }, {
    path: "/users/reset-password/:token?",
    component: _8860e592,
    name: "users-reset-password-token"
  }, {
    path: "/admin/affiliations/:slug/edit",
    component: _0c6bbdc9,
    name: "admin-affiliations-slug-edit"
  }, {
    path: "/admin/affiliations/:slug/projects",
    component: _84bcc708,
    name: "admin-affiliations-slug-projects"
  }, {
    path: "/admin/affiliations/:slug/team",
    component: _497a309c,
    name: "admin-affiliations-slug-team"
  }, {
    path: "/admin/users/:slug/edit",
    component: _156e6210,
    name: "admin-users-slug-edit"
  }, {
    path: "/admin/users/:slug/permissions",
    component: _65cab506,
    name: "admin-users-slug-permissions"
  }, {
    path: "/access/:slug",
    component: _2a637578,
    name: "access-slug"
  }, {
    path: "/company-partners/:slug",
    component: _7eca0d5b,
    name: "company-partners-slug"
  }, {
    path: "/events/:slug",
    component: _79db320e,
    name: "events-slug"
  }, {
    path: "/posts/:id",
    component: _99fc8ce2,
    name: "posts-id"
  }, {
    path: "/projects/:slug",
    component: _126cf897,
    name: "projects-slug"
  }, {
    path: "/users/:slug",
    component: _1fc6edd8,
    name: "users-slug"
  }, {
    path: "/affiliations/:slug?/insights",
    component: _bbed0a4c,
    name: "affiliations-slug-insights"
  }, {
    path: "/affiliations/:slug?/overview",
    component: _f9179d10,
    name: "affiliations-slug-overview"
  }, {
    path: "/affiliations/:slug?/projects",
    component: _194edbbc,
    name: "affiliations-slug-projects"
  }, {
    path: "/affiliations/:slug?/team",
    component: _6cdd0048,
    name: "affiliations-slug-team"
  }, {
    path: "/",
    component: _8ab8e740,
    name: "index"
  }, {
    path: "/posts/:id/comment/:slug",
    component: _99fc8ce2,
    name: "Single Post Page Comment"
  }, {
    path: "/affiliations/:affiliationId/projects/:id/edit",
    component: _126cf897,
    props: {"editing":true},
    name: "Edit Project"
  }, {
    path: "/admin/projects/:id/edit",
    component: _126cf897,
    props: {"editing":true},
    name: "Admin Edit Project Temp"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
